.pc {
	display: block;
}
.mo {
	display: none;
}

@media (max-width: 1000px) {
	.pc {
		display: none;
	}
	.mo {
		display: block;
	}
}

.react-calendar__tile--now {
	background-color: rgba(0, 0, 0, 0.04) !important;
}
.react-calendar__tile:disabled {
	background-color: #fff !important;
}
.react-calendar__navigation button:disabled {
	background-color: #fff !important;
}
